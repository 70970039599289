import TtsConfigField from '../ttsConfigField';
import AsrConfigField from '../asrConfigField';
import VoiceAgentConfigField from '../voiceAgentConfigField';
import DeploymentPathField from '../deploymentPathField';
import ReplicasField from '../replicasField';
import { Errors, FieldProps } from '../../types';

export interface VocodeFieldsProps extends Omit<FieldProps, 'error'> {
  errors: Errors;
}

const VocodeFields = ({
  state,
  disabled,
  errors,
  onUpdate,
}: VocodeFieldsProps) => (
  <>
    <TtsConfigField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled}
      error={errors.ttsConfigId}
    />
    <AsrConfigField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled}
      error={errors.asrConfigId}
    />
    <VoiceAgentConfigField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled}
      error={errors.voiceAgentConfigId}
    />
    <DeploymentPathField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled}
    />
    <ReplicasField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled}
    />
  </>
);

export default VocodeFields;
