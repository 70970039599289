import CopyRetellLlmField from '../copyRetellLlmField';
import LlmIdField from '../llmIdField';
import VoiceIdField from '../voiceIdField';
import VoiceTemperatureField from '../voiceTemperatureField';
import VoiceSpeedField from '../voiceSpeedField';
import ResponsivenessField from '../responsivenessField';
import InterruptionSensitivityField from '../interruptionSensitivityField';
import EnableBackchannelField from '../enableBackchannelField';
import BackchannelFrequencyField from '../backchannelFrequencyField';
import BackchannelWordsField from '../backchannelWordsField';
import ReminderTriggerField from '../reminderTriggerField';
import ReminderMaxCountField from '../reminderMaxCountField';
import BoostedKeywordsField from '../boostedKeywordsField';
import PronunciationDictionaryField from '../pronunciationDictionaryField';
import { Errors, FieldProps } from '../../types';

export interface RetellFieldsProps extends Omit<FieldProps, 'error'> {
  errors: Errors;
}

const RetellFields = ({
  state,
  disabled,
  errors,
  onUpdate,
}: RetellFieldsProps) => (
  <>
    <CopyRetellLlmField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled}
    />
    <LlmIdField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled || !state.copyRetellLlm}
      error={errors.llmId}
    />
    <VoiceIdField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled}
      error={errors.voiceId}
    />
    <VoiceTemperatureField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled}
    />
    <VoiceSpeedField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled}
    />
    <ResponsivenessField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled}
    />
    <InterruptionSensitivityField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled}
    />
    <EnableBackchannelField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled}
    />
    <BackchannelFrequencyField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled || !state.enableBackchannel}
    />
    <BackchannelWordsField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled || !state.enableBackchannel}
    />
    <ReminderTriggerField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled}
    />
    <ReminderMaxCountField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled}
    />
    <BoostedKeywordsField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled}
    />
    <PronunciationDictionaryField
      state={state}
      onUpdate={onUpdate}
      disabled={disabled}
      error={errors.pronunciationDictionary}
    />
  </>
);

export default RetellFields;
