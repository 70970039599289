import { isValidWebsocketBaseUrl } from '../../../../../utils/validators';

export const isValidVocodeWebsocketBaseUrl = (value?: string): string => {
  if (!value) {
    return '';
  }

  const baseError = isValidWebsocketBaseUrl(value);

  if (baseError) {
    return baseError;
  }

  if (!value.endsWith('/llm-websocket/')) {
    return 'Value must ends with a "/llm-websocket/".';
  }

  const versionRegexp = /v\d+-\d+-\d+/gi;

  if (!value.match(versionRegexp)) {
    return 'Version must match following format "vX-Y-Z".';
  }

  return '';
};

export default isValidVocodeWebsocketBaseUrl;
