import { PromptParameters } from '../../api/common/types';
import { AgentType } from '../../features/agentProviders/components/createAgentModal/types';
import { extractPlaceholdersFromTemplate } from '../humanTemplate';

export const isEmptyNumber = (value?: number): string => {
  if (value === null || value === undefined) {
    return 'This field is mandatory.';
  }
  return '';
};

export const isEmptyString = (value?: string): string => {
  if (!value?.trim()) {
    return 'This field is mandatory.';
  }
  return '';
};

export const isValidJsonString = (value?: string): string => {
  if (!value) return '';

  try {
    const json = JSON.parse(value);

    if (Array.isArray(json)) {
      return 'Value must be a single json object.';
    }

    return '';
  } catch (error) {
    return 'Value must be a valid json object.';
  }
};

export const isValidJson = (value?: Record<string, unknown>): string => {
  if (!value) return '';

  if (Array.isArray(value)) {
    return 'Value must be a single json object.';
  }

  return '';
};

export const isValidJsonArray = (value?: string): string => {
  if (!value) return '';

  try {
    const json = JSON.parse(value);

    if (!Array.isArray(json)) {
      return 'Value must be an array of valid json objects.';
    }

    return '';
  } catch (error) {
    return 'Value must be an array of valid json objects.';
  }
};

export const isValidWebsocketBaseUrl = (value?: string): string => {
  if (!value) {
    return '';
  }

  const websocketUrlRegexp = /wss?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\\/=]*)/gi;

  if (!value.match(websocketUrlRegexp)) {
    return 'Value must be a valid websocket URL.';
  }

  if (!value.endsWith('/')) {
    return 'Value must ends with a slash.';
  }

  return '';
};

export const isValidUrl = (value?: string): string => {
  if (!value) {
    return '';
  }

  const urlRegexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\\/=]*)/gi;

  if (!value.match(urlRegexp)) {
    return 'Value must be a valid URL.';
  }

  return '';
};

export const validatePronunciationDictionary = (value?: string): string => {
  if (!value) {
    return '';
  }

  const hasError = isValidJsonArray(value);

  if (hasError) {
    return hasError;
  }

  const missingFieldsErrors: Record<string, boolean> = {};

  const allowedAlphabetValues = ['ipa', 'cmu'];

  let alphabedError = false;

  JSON.parse(value).forEach((item: Record<string, string>) => {
    if (!item.word?.trim()) {
      missingFieldsErrors.word = true;
    }

    if (!item.alphabet?.trim()) {
      missingFieldsErrors.alphabet = true;
    } else if (!allowedAlphabetValues.includes(item.alphabet?.trim())) {
      alphabedError = true;
    }

    if (!item.phoneme?.trim()) {
      missingFieldsErrors.phoneme = true;
    }
  });

  const missingFields = Object.keys(missingFieldsErrors);

  if (missingFields.length) {
    return `${missingFields.join(', ')} ${missingFields.length === 1 ? 'is' : 'are'} required`;
  }

  if (alphabedError) {
    return '"alphabet" should be one of "ipa", "cmu"';
  }

  return '';
};

interface PromptParameterRowErrors {
  key?: string;
  name?: string;
  options?: string;
}

export type PromptParametersErrors = Record<number, PromptParameterRowErrors> | null;

export const validatePromptParams = (params?: PromptParameters[]): PromptParametersErrors => {
  if (!params) {
    return null;
  }

  if (!params.length) {
    return null;
  }

  const errors = params.reduce((acc, item, index) => {
    const rowErrors: PromptParameterRowErrors = {};

    if (!item.key?.trim()) {
      rowErrors.key = 'This field is mandatory.';
    }

    if (!item.name?.trim()) {
      rowErrors.name = 'This field is mandatory.';
    }

    if (item.type === 'select' && !item.options?.length) {
      rowErrors.options = 'This field is mandatory.';
    }

    if (Object.keys(rowErrors).length) {
      acc[index] = rowErrors;
    }

    return acc;
  }, {} as Record<number, PromptParameterRowErrors>);

  if (Object.keys(errors).length) {
    return errors;
  }

  return null;
};

export const validateAgentName = (value?: string, initialValue?: string) => {
  const isEmpty = isEmptyString(value);

  if (isEmpty) {
    return isEmpty;
  }

  if (value === initialValue) {
    return 'The agent name must be different from the original one';
  }

  return '';
};

export const isAlphaNumericText = (value?: string): string => {
  if (!value?.trim()) {
    return '';
  }

  if (/^[a-z0-9]+$/i.test(value.trim())) {
    return '';
  }

  return 'Value must contain only alpha-numeric characters.';
};

export const validateHumanTemplate = (template?: string): string => {
  if (!template?.trim()) {
    return 'This field is mandatory.';
  }

  const placeholders = extractPlaceholdersFromTemplate(template);

  if (placeholders.some((placeholder) => !placeholder)) {
    return 'The placeholder name must not be empty';
  }

  return '';
};

export const validatePersonaId = (agentType: AgentType, personaId?: number): string => {
  if (agentType === AgentType.agent) {
    return '';
  }

  if (personaId === null || personaId === undefined) {
    return 'This field is mandatory.';
  }

  return '';
};

export const isEmptyArray = <T>(value?: Array<T>): string => {
  if (!value?.length) {
    return 'This field is mandatory.';
  }
  return '';
};
