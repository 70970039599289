import { useCallback, useEffect, useState } from 'react';

import { AgentProvider, OrchestratorProviderTypes } from '../../../../../api/agentProviders/types';
import {
  isEmptyNumber,
  isEmptyString,
  isValidJsonString,
  isValidWebsocketBaseUrl,
  validateAgentName,
  validatePronunciationDictionary,
  validatePersonaId,
} from '../../../../../utils/validators';
import { isValidVocodeWebsocketBaseUrl } from '../utils/validators';
import { CreateAgentModalState, Errors } from '../types';

interface UseCreateAgentModalValidation {
  isOpen: boolean;
  initialAgent: AgentProvider | null;
}

const initialState: Errors = {};

const useCreateAgentModalValidation = ({ isOpen, initialAgent }: UseCreateAgentModalValidation) => {
  const [errors, setErrors] = useState<Errors>(initialState);

  const isValid = useCallback(async (state: CreateAgentModalState) => {
    const isVocodeProvider = state.orchestratorProviderType === OrchestratorProviderTypes.vocode;
    const isRetellProvider = state.orchestratorProviderType === OrchestratorProviderTypes.retell;

    const newState = {
      providerType: isEmptyString(state.providerType),
      apiVersion: isEmptyString(state.apiVersion),
      class: isEmptyString(state.class),
      functionTools: isValidJsonString(state.functionTools),
      vectorDbConfig: isValidJsonString(state.vectorDbConfig),
      dataModelConfig: isValidJsonString(state.dataModelConfig),
      dynamicPrompts: isValidJsonString(state.dynamicPrompts),
      personaId: validatePersonaId(state.agentType, state.personaId),

      campaignId: isEmptyNumber(state.campaignId),
      orchestratorProviderType: isEmptyString(state.orchestratorProviderType),
      direction: isEmptyNumber(state.direction),
      agentName: validateAgentName(
        state.agentName,
        initialAgent?.agentName ?? initialAgent?.orchestratorProvider.config?.AgentName,
      ),
      websocketBaseUrl: isRetellProvider
        ? isValidWebsocketBaseUrl(state.websocketBaseUrl)
        : isValidVocodeWebsocketBaseUrl(state.websocketBaseUrl),
      voiceId: isRetellProvider ? isEmptyString(state.voiceId) : '',
      pronunciationDictionary: isRetellProvider ? validatePronunciationDictionary(state.pronunciationDictionary) : '',
      asrConfigId: isVocodeProvider ? isEmptyNumber(state.asrConfigId) : '',
      ttsConfigId: isVocodeProvider ? isEmptyNumber(state.ttsConfigId) : '',
      voiceAgentConfigId: isVocodeProvider ? isEmptyNumber(state.voiceAgentConfigId) : '',

      llmId: state.copyRetellLlm ? isEmptyString(state.llmId) : undefined,
    };

    setErrors(newState);

    return Object.values(newState).every((field) => !field);
  }, [initialAgent?.agentName, initialAgent?.orchestratorProvider.config?.AgentName]);

  const clearErrorForField = useCallback((propName: string) => {
    setErrors((state) => ({
      ...state,
      [propName]: '',
    }));
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setErrors(initialState);
    }
  }, [isOpen]);

  return {
    errors,
    isValid,
    clearErrorForField,
  };
};

export default useCreateAgentModalValidation;
