import { Form, Modal, Typography } from 'antd';
import ModalFooter from '@marrlab-app-shared/components/modalFooter';

import ProviderTypeField from './components/providerTypeField';
import ApiVersionField from './components/apiVersionField';
import ClassField from './components/classField';
import LlmPromptField from './components/llmPromptField';
import PromptField from './components/promptField';
import FunctionToolsField from './components/functionToolsField';
import VectorDbConfigField from './components/vectorDbConfigField';
import AgentNameField from './components/agentNameField';
import OrchestratorProviderTypeField from './components/orchestratorProviderTypeField';
import CampaignField from './components/campaignField';
import MultiplePromptsField from './components/multiplePromptsField';
import IntegrationField from './components/integrationField';
import WebsocketBaseUrlField from './components/websocketBaseUrlField';
import DataModelConfigField from './components/dataModelConfigField';
import EmbeddingAliasField from './components/embeddingAliasField';
import ChatModelAliasField from './components/chatModelAliasField';
import DataModelAliasField from './components/dataModelAliasField';
import DirectionField from './components/directionField';
import DynamicPromptsField from './components/dynamicPromptsField';
import PersonaField from './components/personaField';

import useCreateAgentModalLogic from './hooks/useCreateAgentModalLogic';
import useCreateAgentModalState from './hooks/useCreateAgentModalState';
import useCreateAgentModalValidation from './hooks/useCreateAgentModalValidation';
import {
  AgentProvider,
  CreateAgentParams,
  OrchestratorProviderTypes,
} from '../../../../api/agentProviders/types';
import { SCROLLABLE_CONTAINER_ID } from './constants';
import { CreateAgentDialogPrefilledData } from '../../types';
import { AgentType } from './types';

import './index.scss';
import VocodeFields from './components/vocodeFields';
import RetellFields from './components/retellFields';

interface CreateAgentModalProps {
  isOpen: boolean;
  previewMode: boolean;
  prefilledData: CreateAgentDialogPrefilledData | null;
  initialAgent: AgentProvider | null;
  onConfirm: (params: CreateAgentParams) => Promise<void>;
  onCancel: () => void;
}

const CreateAgentModal = ({
  isOpen,
  previewMode,
  prefilledData,
  initialAgent,
  onConfirm: onConfirmBase,
  onCancel,
}: CreateAgentModalProps) => {
  const {
    errors,
    isValid,
    clearErrorForField,
  } = useCreateAgentModalValidation({ isOpen, initialAgent });

  const {
    isEdit,
    state,
    onPropertyUpdate,
  } = useCreateAgentModalState({
    isOpen,
    prefilledData,
    initialAgent,
    clearErrorForField,
  });

  const { saving, onConfirm } = useCreateAgentModalLogic({
    state,
    isValid,
    onConfirm: onConfirmBase,
  });

  const isHumanAgent = state.agentType === AgentType.humanAgent;

  let title = isHumanAgent ? 'Create human agent' : 'Create agent';

  if (isEdit) {
    title = `Edit & copy agent #${initialAgent?.agentProviderId}`;
  }

  if (previewMode) {
    title = 'Preview agent';
  }

  const disabled = saving || previewMode;

  const isVocode = state.orchestratorProviderType === OrchestratorProviderTypes.vocode;
  const isRetell = state.orchestratorProviderType === OrchestratorProviderTypes.retell;

  return (
    <Modal
      title={title}
      open={isOpen}
      onOk={onConfirm}
      onCancel={onCancel}
      width="800px"
      footer={(
        <ModalFooter
          disabled={saving}
          showSaveButton={!previewMode}
          saveButtonLabel={isEdit ? 'Save' : 'Create'}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    >
      <Form
        id={SCROLLABLE_CONTAINER_ID}
        layout="vertical"
        className="create-agent-modal-scrollable-area py-4"
      >
        <Typography.Title level={5} className="mt-0">
          Agent provider fields
        </Typography.Title>
        {isHumanAgent && (
          <PersonaField
            state={state}
            onUpdate={onPropertyUpdate}
            disabled={disabled}
            error={errors.personaId}
          />
        )}
        <ProviderTypeField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.providerType}
        />
        <ApiVersionField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.apiVersion}
        />
        <ClassField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.class}
        />
        <LlmPromptField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled || isHumanAgent}
        />
        <PromptField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <MultiplePromptsField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <FunctionToolsField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.functionTools}
        />
        <IntegrationField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <VectorDbConfigField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.vectorDbConfig}
        />
        <DataModelConfigField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.dataModelConfig}
        />
        <DynamicPromptsField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.dynamicPrompts}
        />
        <EmbeddingAliasField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <ChatModelAliasField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <DataModelAliasField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <Typography.Title level={5}>Orchestrator provider fields</Typography.Title>
        <CampaignField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.campaignId}
        />
        <OrchestratorProviderTypeField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.orchestratorProviderType}
        />
        <DirectionField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.direction}
        />
        <AgentNameField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.agentName}
        />
        <WebsocketBaseUrlField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled || (isRetell && !!state.copyRetellLlm)}
          error={errors.websocketBaseUrl}
        />
        {isVocode ? (
          <VocodeFields
            state={state}
            errors={errors}
            onUpdate={onPropertyUpdate}
            disabled={disabled}
          />
        ) : (
          <RetellFields
            state={state}
            errors={errors}
            onUpdate={onPropertyUpdate}
            disabled={disabled}
          />
        )}
      </Form>
    </Modal>
  );
};

export default CreateAgentModal;
