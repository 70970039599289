import {
  Button,
  Card,
  Flex,
  Pagination,
  Table,
  Typography,
} from 'antd';
import { CloseOutlined, ReloadOutlined, SettingOutlined } from '@ant-design/icons';
import useSearchDebounce from '@marrlab-app-shared/hooks/state/useSearchDebounce';
import FiltersPanel from '@marrlab-app-shared/components/filtersPanel';

import Search from './components/search';
import CampaignFilter from './components/campaignFilter';
import ColumnSettingsModal from './components/columnSettingsModal';
import FunctionToolsFilter from './components/functionToolsFilter';
import IntegrationFilter from './components/integrationFilter';
import AgentTypeFilter from './components/agentTypeFilter';
import VectorDbConfigFilter from './components/vectorDbConfigFilter';
import ApiVersionFilter from './components/apiVersionFilter';
import ProviderTypeFilter from './components/providerTypeFilter';
import AgentNameFilter from './components/agentNameFilter';
import StatusFilter from './components/statusFilter';
import NumberAssignmentFilter from './components/numberAssignmentFilter';
import DeleteAgentProvider from './providers/deleteAgentProvider';
import CreateAgentProvider from './providers/createAgentProvider';
import AssignNumberProvider from './providers/assignNumberProvider';
import UnassignNumberProvider from './providers/unassignNumberProvider';
import DeployAgentProvider from './providers/deployAgentProvider';
import CreateAgentButton from './components/createAgentButton';
import DeployedFilter from './components/deployedFilter';

import useColumnsSettingsModal from './hooks/useColumnsSettingsModal';
import useAgentProviderData from './hooks/useAgentProviderData';
import usePagination from '../../hooks/table/usePagination';
import useSorting from './hooks/useSorting';
import useTableConfiguration from './hooks/useTableConfiguration';
import useFilters from './hooks/useFilters';
import { idColumnKey } from './constants/columnsInitialState';
import config from '../../utils/config';
import OrchestratorProviderTypeFilter from './components/orchestratorProviderTypeFilter';

import './index.scss';

const AgentProviders = () => {
  const {
    columns,
    sticky,
    scroll,
    setColumns,
  } = useTableConfiguration();
  const {
    skip,
    page,
    pageSize,
    resetPage,
    onPageChange,
    showTotal,
  } = usePagination();
  const { search, updateSearch } = useSearchDebounce({ onDebounce: resetPage });
  const { filters, onFiltersUpdate, clearFilters } = useFilters({ resetPage });
  const { sortBy, sortDirection, onSortChange } = useSorting({ setColumns });
  const { data, loading, fetchAgentProviders } = useAgentProviderData({
    skip,
    take: pageSize,
    sortBy,
    order: sortDirection,
    search,
    ...filters,
  });
  const columnsSettingsModal = useColumnsSettingsModal({ setColumns });

  return (
    <CreateAgentProvider fetchAgentProviders={fetchAgentProviders}>
      <DeleteAgentProvider fetchAgentProviders={fetchAgentProviders}>
        <AssignNumberProvider fetchAgentProviders={fetchAgentProviders}>
          <UnassignNumberProvider fetchAgentProviders={fetchAgentProviders}>
            <DeployAgentProvider fetchAgentProviders={fetchAgentProviders}>
              <Card
                title={(
                  <Typography.Title
                    level={4}
                    className="agent-providers-title"
                  >
                    Agent providers
                  </Typography.Title>
                )}
                className="bg-white rounded shadow-lg"
              >
                <Flex className="mb-4" justify="flex-end">
                  <CreateAgentButton />
                </Flex>
                <FiltersPanel
                  defaultOpen
                  search={(
                    <Flex gap={16}>
                      <Search
                        searchBy={filters.searchBy}
                        updateSearch={updateSearch}
                        onFiltersUpdate={onFiltersUpdate}
                      />
                      <Button
                        icon={<ReloadOutlined />}
                        onClick={fetchAgentProviders}
                      >
                        Reload
                      </Button>
                      <Button
                        className="ml-auto"
                        icon={<SettingOutlined />}
                        onClick={columnsSettingsModal.open}
                      >
                        Columns settings
                      </Button>
                    </Flex>
                  )}
                >
                  <Flex gap={16} vertical>
                    <Flex gap={16} wrap="wrap">
                      <StatusFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
                      {config.TESTING_TOOLS_ENABLED && (
                        <AgentTypeFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
                      )}
                      <CampaignFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
                      <FunctionToolsFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
                      <IntegrationFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
                      <VectorDbConfigFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
                      <ApiVersionFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
                      <ProviderTypeFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
                      <OrchestratorProviderTypeFilter
                        filters={filters}
                        onFiltersUpdate={onFiltersUpdate}
                      />
                      <NumberAssignmentFilter
                        filters={filters}
                        onFiltersUpdate={onFiltersUpdate}
                      />
                      <AgentNameFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
                      <DeployedFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
                    </Flex>
                    <Flex justify="flex-end">
                      <Button
                        danger
                        onClick={clearFilters}
                        icon={<CloseOutlined />}
                      >
                        Clear filters
                      </Button>
                    </Flex>
                  </Flex>
                </FiltersPanel>
                <div className="table-wrapper">
                  <Table
                    rowKey={idColumnKey}
                    dataSource={data?.data}
                    columns={columns}
                    loading={loading}
                    scroll={scroll}
                    pagination={false}
                    sticky={sticky}
                    onChange={onSortChange}
                  />
                </div>
                <Flex justify="flex-end">
                  <Pagination
                    hideOnSinglePage
                    className="mt-4"
                    total={data?.total}
                    showTotal={showTotal}
                    pageSize={pageSize}
                    current={page}
                    showSizeChanger
                    onChange={onPageChange}
                    disabled={loading}
                  />
                </Flex>
                <ColumnSettingsModal
                  isOpen={columnsSettingsModal.isOpen}
                  onConfirm={columnsSettingsModal.onConfirm}
                  onCancel={columnsSettingsModal.onCancel}
                  initialColumns={columns}
                />
              </Card>
            </DeployAgentProvider>
          </UnassignNumberProvider>
        </AssignNumberProvider>
      </DeleteAgentProvider>
    </CreateAgentProvider>
  );
};

export default AgentProviders;
